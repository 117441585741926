<template>
  <v-container fluid class="pa-0">
    <v-form autoComplete='none' v-model="localValid">
      <input autoComplete='none' name="hidden" type="text" style="display:none;" role="email">
      <input role="presentation" autocomplete="off" hidden />
      <v-row>
        <v-col>
          <v-text-field
            filled
            :rules="[presenceRule]"
            :label="$translations.t('models.User.fields.firstname')"
            v-model="firstname"
            :loading="loading"
            @input="$emit('input', localValue)"
            hide-details="auto"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            filled
            :rules="[presenceRule]"
            :label="$translations.t('models.User.fields.lastname')"
            v-model="lastname"
            :loading="loading"
            @input="$emit('input', localValue)"
            hide-details="auto"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            filled
            :rules="[emailRule, presenceRule]"
            :label="$translations.t('models.User.fields.email')"
            v-model="email"
            autocomplete="username"
            :loading="loading"
            :disabled="disableEmail"
            @input="$emit('input', localValue)"
            hide-details="auto"
          ></v-text-field>
        </v-col>
        <v-col>
          <GroupsAutocomplete
            v-model="groupUids"
            item-value="uid"
            @input="$emit('input', localValue)"
            multiple
            :clearable="false"
            mandatory
            :error="!groupUids.length"
          ></GroupsAutocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            filled
            label="Password"
            v-model="password"
            :loading="loading"
            autocomplete="new-password"
            type="password"
            @input="$emit('input', localValue)"
            hide-details="auto"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            filled
            label="Conferma password"
            :rules="[passwordConfirmed, presenceIfPasswordSet]"
            v-model="passwordConfirmation"
            :loading="loading"
            type="password"
            @input="$emit('input', localValue)"
            hide-details="auto"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-checkbox
            label="Attivo"
            v-model="enabled"
            @change="$emit('input', localValue)"
            style="width: 30px"
          ></v-checkbox>
        </v-col>
        <v-col v-if="!!currentUser && currentUser.system">
          <v-checkbox
            label="Amministratore"
            v-model="administrator"
            @change="$emit('input', localValue)"
            style="width: 30px"
          ></v-checkbox>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import Vue from 'vue'
import GroupsAutocomplete from '@/components/common/GroupsAutocomplete.vue'

export default {
  name: "UserForm",
  components: {
    GroupsAutocomplete
  },
  data: function() {
    return {
      firstname: this.value ? this.value.firstname : undefined,
      lastname: this.value ? this.value.lastname : undefined,
      email: this.value ? this.value.email : undefined,
      enabled: this.value ? this.value.enabled : undefined,
      administrator: this.value ? this.value.administrator : undefined,
      password: undefined,
      passwordConfirmation: undefined,
      groupUids: this.value && this.value.groups ? this.value.groups.map(el => el.uid) : [],
      id: this.value ? this.value.id : undefined,
      localValid: false,
      currentUser: undefined
    }
  },
  props: {
    value: {},
    valid: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
      default: false
    },
    disableEmail: {
      type: Boolean,
      default: false
    },
    bus: {
      type: Object,
      default: () => new Vue()
    }
  },
  mounted: function() {
    this.$emit('update:valid', this.localValid)
  },
  methods: {
    emailRule: function (value) {
      // don't worry, that's form vuetify documentation
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || "Invalid e-mail.";
    },
    presenceRule(value) {
      return !!value || 'Il campo è obbligatorio'
    },
    presenceIfPasswordSet(value) {
      return (!this.password || (!!this.password && !!value)) || 'il campo è obbligatorio'
    },
    passwordConfirmed(value) {
      return value === this.password || 'Le password non coincidono'
    }
  },
  watch: {
    value(newValue) {
      if(!!newValue) {
        this.firstname = newValue.firstname
        this.lastname = newValue.lastname
        this.enabled = newValue.enabled
        this.administrator = newValue.administrator
        this.email = newValue.email
        this.id = newValue.id
        this.groupUids = newValue.groups.map(el => el.uid)
      }
    },
    localValid() {
      this.$emit('update:valid', this.localValid)
    }
  },
  computed: {
    localValue() {
      return {
        uid: this.value ? this.value.uid : undefined,
        firstname: this.firstname,
        lastname: this.lastname,
        enabled: this.enabled,
        administrator: this.administrator,
        email: this.email,
        password: this.password,
        groups: this.groupUids.map((uid) => {
          return {
            uid: uid
          }
        }),
        id: this.id
      }
    }
  },
  currentUser: {
    bind: 'currentUser'
  }
}
</script>

<style>

</style>