import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js'
import currentUserService from "@/services/currentUser.service";

class GroupsService {

  options() {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios.get(apiUrl + '/users/groups/options').then((response) => {
        if(response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  list() {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/users/groups/list', {
        page: 1,
        rowPerPage: 200
      }).then((response) => {
        if (response.success) {
          resolve(response.results.rows)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  async create(group) {
    const apiUrl = UrlKeeper.getUrl('authenticator')
    
    const currentUser = await currentUserService.getUser()
    const response = await axios.post(apiUrl + '/users/groups/create', {
      ...group,
      licenses: currentUser.licenses
    })

    if (response.success) return response.results
    else throw new Error(response.results)
  }

  update(group) {
    const apiUrl = UrlKeeper.getUrl('authenticator')
    delete group.id
    
    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/users/groups/update', group).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  delete(group) {
    const apiUrl = UrlKeeper.getUrl('authenticator')
    
    return new Promise((resolve, reject) => {
      axios.get(apiUrl + '/users/groups/delete', group).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

}

export default new GroupsService();
