<template>
  <v-container fluid class="pa-0">
    <div class="d-flex justify-center mt-2" v-if="loadingPermissions">Caricamento permessi ...</div>
    <div class="d-flex justify-center" v-else-if="!!permissions">
      <table class="table">
        <thead>
          <tr>
            <th></th>
            <th>Gestione</th>
            <th>Visualizzazione</th>
            <th>Eliminazione</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(actions, resource) in permissions" :key="resource">
            <td class="pl-4 text-body">{{$translations.t('permissions.resources.' + resource)}}</td>
            <td v-for="actionKey in ['manage', 'view', 'delete']" :key="actionKey">
              <div class="d-flex justify-center">
                <v-progress-circular
                  v-if="loadingAction == actionKey && loadingResource == resource"
                  indeterminate
                  color="primary"
                  :size="27"
                ></v-progress-circular>
                <v-checkbox
                  v-else
                  :disabled="!!loadingAction && !!loadingResource"
                  :input-value="canGroupedByResource[resource][actionKey]"
                  @change="(value) => handleCheckboxChange(value, resource, actionKey)"
                ></v-checkbox>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </v-container>
</template>

<style scoped>
  .table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    max-width: 1000px;
  }

  .table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
</style>

<script>
import Vue from 'vue'
import permissionsService from '@/services/auth/permissions.service.js'

export default {
  name: "GroupForm",
  data: function() {
    return {
      loadingPermissions: false,
      permissions: undefined,
      loadingResource: undefined,
      loadingAction: undefined
    }
  },
  props: {
    groupId: {
      type: Number
    },
    groupUid: {
      type: String
    },
    bus: {
      type: Object,
      default: () => new Vue()
    }
  },
  mounted: function() {
    this.loadPermissions()
  },
  methods: {
    loadPermissions() {
      this.loadingPermissions = true
      permissionsService.list().then((results) => {
        this.loadingPermissions = false
        this.permissions = results
      })
    },
    handleCheckboxChange(value, resource, action) {
      this.loadingAction = action
      this.loadingResource = resource

      if(value) {
        permissionsService.addToGroup(resource, action, {
          uid: this.groupUid,
          id: this.groupId
        }).then(() => {
          let index = this.permissions[resource].findIndex((el) => el.permission == action)

          if(index >= 0) {
            if(!this.permissions[resource][index].groupIds) this.permissions[resource][index].groupIds = []
            this.permissions[resource][index].groupIds.push(this.groupId)
          } else {
            this.permissions[resource].push({ permission: action, groupIds: [this.groupId] })
          }

          this.permissions = {...this.permissions}

          this.$delegates.snackbar('Permesso impostato correttamente')
        }).catch(() => {
          this.$delegates.snackbar('Errore durante l\'impostazione del permesso')
        }).finally(() => {
          this.loadingAction = undefined
          this.loadingResource = undefined
        })
      } else {
        permissionsService.removeFromGroup(resource, action, {
          uid: this.groupUid,
          id: this.groupId
        }).then(() => {
          this.$delegates.snackbar('Permesso impostato correttamente')

          this.permissions[resource] = this.permissions[resource].filter((el) => el.permission != action)
        }).catch(() => {
          this.$delegates.snackbar('Errore durante l\'impostazione del permesso')
        }).finally(() => {
          this.loadingAction = undefined
          this.loadingResource = undefined
        })
      }
    }
  },
  computed: {
    canGroupedByResource() {
      let grouped = {}
      for(const [resource, actions] of Object.entries(this.permissions)) {
        grouped[resource] = {}
        let actionKeys = ['manage', 'view', 'delete']

        for(let k = 0; k < actionKeys.length; k += 1) {
          let action = actionKeys[k]

          let manageAction = actions.find((e) => e.permission == action)

          if(!!manageAction && !!manageAction.groupIds) {
            grouped[resource][action] = manageAction.groupIds.includes(this.groupId)
          } else {
            grouped[resource][action] = false
          }
        }
      }

      return grouped
    }
  }
}
</script>

<style>

</style>